<template>
  <div class="bgStyle wrapper">
    <a-row type="flex" justify="end">
      <a-button
        type="primary"
        class="back"
        @click="toBack"
        style="margin-bottom: 12px"
      >
        返回
      </a-button>
    </a-row>
    <a-row :gutter="26">
      <a-col :span="12">
        <div class="top">
          <a-collapse default-active-key="1">
            <a-collapse-panel
              key="1"
              :header="'版本号: ' + objCurrent.docVersion"
            >
              <a-row class="l-row">
                <a-col :span="8"
                  >文档名称:
                  <span>{{ objCurrent.docName }}</span>
                </a-col>
                <a-col :span="8"
                  >文档编码:
                  <span>{{ objCurrent.docCode }}</span>
                </a-col>
                <a-col :span="8"
                  >文档类型:
                  <span>{{ filterType(objCurrent.docType) }}</span>
                </a-col>
              </a-row>

              <a-row class="l-row">
                <a-col :span="8"
                  >大小:
                  <span>{{ objCurrent.docSize + "KB" }}</span>
                </a-col>
                <a-col :span="8"
                  >文档格式:
                  <span>{{ objCurrent.docFormat }}</span>
                </a-col>
                <a-col :span="8"
                  >上传人:
                  <span>{{ objCurrent.docUploader }}</span>
                </a-col>
              </a-row>
              <a-row class="l-row">
                <a-col :span="8"
                  >上传时间:
                  <span>{{ objCurrent.creationTime | filterDate }}</span>
                </a-col>
                <a-col :span="8"
                  >最后修改人:
                  <span>{{ objCurrent.docLastModifierName }}</span>
                </a-col>
                <a-col :span="8"
                  >修改时间:
                  <span>{{
                    objCurrent.docLastModificationTime | filterDate
                  }}</span>
                </a-col>
              </a-row>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </a-col>
      <a-col :span="12">
        <div class="top">
          <a-collapse default-active-key="1">
            <a-collapse-panel
              key="1"
              :header="'版本号: ' + objHistory.docVersion"
            >
              <a-row class="l-row">
                <a-col :span="8"
                  >文档名称:
                  <span>{{ objHistory.docName }}</span>
                </a-col>
                <a-col :span="8"
                  >文档编码:
                  <span>{{ objHistory.docCode }}</span>
                </a-col>
                <a-col :span="8"
                  >文档类型:
                  <span>{{ filterType(objHistory.docType) }}</span>
                </a-col>
              </a-row>

              <a-row class="l-row">
                <a-col :span="8"
                  >大小:
                  <span>{{ objHistory.docSize + "KB" }}</span>
                </a-col>
                <a-col :span="8"
                  >文档格式:
                  <span>{{ objHistory.docFormat }}</span>
                </a-col>
                <a-col :span="8"
                  >上传人:
                  <span>{{ objHistory.docUploader }}</span>
                </a-col>
              </a-row>
              <a-row class="l-row">
                <a-col :span="8"
                  >上传时间:
                  <span>{{ objHistory.creationTime | filterDate }}</span>
                </a-col>
                <a-col :span="8"
                  >最后修改人:
                  <span>{{ objHistory.docLastModifierName }}</span>
                </a-col>
                <a-col :span="8"
                  >修改时间:
                  <span>{{
                    objHistory.docLastModificationTime | filterDate
                  }}</span>
                </a-col>
              </a-row>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </a-col>

      <div class="bgStyle table wrapper">
        <div class="left table-cell" :style="leftStyle">
          <a class="left-collapse collapse" @click="setLeftCollapse">
            <a-icon :type="leftIcon" :style="curDocLeftRightIcon"></a-icon>
          </a>
          <PreviewDoc :doc="objCurrent" />
          <a class="right-collapse collapse" @click="setRightCollapse">
            <a-icon :type="rightIcon" :style="curDocLeftRightIcon"></a-icon>
          </a>
        </div>
        <div class="right table-cell" :style="rightStyleForDoc2">
          <a class="left-collapse collapse" @click="setLeftCollapse">
            <a-icon :type="leftIcon" :style="hisDocLeftRightIcon"></a-icon>
          </a>
          <PreviewDoc :doc="objHistory" />
          <a class="right-collapse collapse" @click="setLeftCollapseForDoc2">
            <a-icon
              :type="rightIconForDoc2"
              :style="hisDocLeftRightIcon"
            ></a-icon>
          </a>
        </div>
      </div>
    </a-row>
  </div>
</template>
<script>
import { versionCompare } from "@/api/figureDocument.js";
import PreviewDoc from "@/components/Preview/Index.vue";
import { findLeftBar } from "@/utils/common.js";

export default {
  components: { PreviewDoc },
  props: {
    currentVersionId: { type: String, default: () => "" },
    historyVersionId: { type: String, default: () => "" },
  },
  created() {
    this.getDocType();
    this.getData();
  },
  data() {
    return {
      isHiddenInfomation: false,
      isHiddenInfomationForDoc2: false,
      objCurrent: {},
      objHistory: {},

      objDocType: {},
    };
  },
  filters: {
    filterDate(val) {
      if (val) {
        let dt = val.substring(0, 19).replace("T", " ");
        return dt;
      } else {
        return "";
      }
    },
  },
  methods: {
    getDocType() {
      this.objDocType = JSON.parse(localStorage.getItem("objDocType"));
    },
    filterType(val) {
      return this.objDocType[val] || "";
    },
    getData() {
      let params = {
        currentVersionId: this.currentVersionId,
        historyVersionId: this.historyVersionId,
      };
      console.log("params", params);
      versionCompare(params).then((res) => {
        console.log("res", res);
        this.objCurrent = res[0];
        this.objHistory = res[1];
      });
    },
    setLeftCollapse() {
      const lb = findLeftBar(this);
      let $parent = lb;
      do {
        $parent = $parent.$parent;
      } while (
        typeof $parent.collapsed === "undefined" &&
        $parent != this.$root
      );
      const rs = !this.isHiddenSideBar;
      $parent.collapsed = rs;
    },
    setRightCollapse() {
      this.isHiddenInfomation = !this.isHiddenInfomation;
    },
    setLeftCollapseForDoc2() {
      this.isHiddenInfomationForDoc2 = !this.isHiddenInfomationForDoc2;
    },
    toBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    leftStyle() {
      if (this.isHiddenInfomation) {
        return "width:100%";
      }
      if (this.isHiddenInfomationForDoc2) {
        return "width:0%;pointer-events:none;";
      }
      return "width:50%";
    },
    rightStyle() {
      if (this.isHiddenInfomation) {
        return "width: 0;overflow: hidden;visibility: hidden;display: block;";
      }
      return "width:50%;";
    },
    rightStyleForDoc2() {
      if (this.isHiddenInfomationForDoc2) {
        return "width:100%";
      }
      return "width:50%";
    },
    curDocLeftRightIcon() {
      if (this.isHiddenInfomationForDoc2) {
        return "visibility:hidden";
      }
      return "display:display;";
    },
    hisDocLeftRightIcon() {
      if (this.isHiddenInfomation) {
        return "visibility:hidden";
      }
      return "display:display;pointer-events:none;";
    },
    leftIcon() {
      return this.isHiddenSideBar ? "double-right" : "double-left";
    },
    rightIcon() {
      return this.isHiddenInfomation ? "double-left" : "double-right";
    },
    rightIconForDoc2() {
      return this.isHiddenInfomationForDoc2 ? "double-left" : "double-right";
    },
    isHiddenSideBar() {
      const lb = findLeftBar(this);
      return lb ? lb.collapsed : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #fff !important;
}

.top {
  margin-bottom: 16px;
}

.l-row {
  line-height: 36px;
  > .ant-col {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.table {
  display: table;
  width: calc(100% - 40px);
  height: calc(100vh - 300px);
  padding: 0 !important;
  .table-cell {
    display: table-cell;
  }
  .left {
    position: relative;
    vertical-align: middle;
    padding: 2em;
    .collapse {
      width: 1em;
      text-align: left;
      position: absolute;
      top: calc(50% - 6em);
      padding: 5em 0;
      :hover {
        opacity: 0.8;
      }
    }
    .left-collapse {
      left: 0.5em;
    }
    .right-collapse {
      right: 0.5em;
    }
  }
  .right {
    position: relative;
    vertical-align: middle;
    padding: 2em;
    .collapse {
      width: 1em;
      text-align: right;
      position: absolute;
      float: right;
      top: calc(50% - 6em);
      padding: 5em 0;
      :hover {
        opacity: 0.8;
      }
    }
    .left-collapse {
      left: 0.5em;
    }
    .right-collapse {
      right: 0.5em;
    }
  }
}
</style>
